import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Typography,
  Card,
} from "antd";
import { passwordReset } from "../api/Password";
const { Title, Text } = Typography;
export default function PasswordReset() {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  const onFailedSubmit = (error) => {
    openNotificationWithIcon(
      "error",
      "Failed to submit",
      "You have missed some important information. Please fill those up to complete action."
    );
  };

  const docreset = (msg) => {
    if (msg) {
      form.resetFields();
      setloading(false);
    } else {
      setloading(false);
    }
  };

  const onSubmitFile = (values) => {
    setloading(true);
    let formdata = new FormData();
    formdata.append("old_password", values.old_password);
    formdata.append("new_password", values.new_password);
    formdata.append("confirm_password", values.confirm_password);
    passwordReset(formdata, openNotificationWithIcon, docreset);
  };

  return (
    <Card>
      <Form
        name="basic"
        layout="vertical"
        form={form}
        onFinish={onSubmitFile}
        onFinishFailed={onFailedSubmit}
      >
        <Row gutter={[16, 0]} style={{ marginBottom: "1rem" }}>
          <Col xs={{ span: 24 }} md={{ span: 12 }} style={{ padding: "1rem" }}>
            <img
              width="200px"
              style={{ marginBottom: "0.5rem" }}
              src={require("../assets/vectors/password.svg")}
              alt=""
            />
            <Title level={3}>Reset Password</Title>
            <Text type="secondary">
              Students can reset their password from here.
            </Text>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label="Old Password"
                  name="old_password"
                  rules={[
                    {
                      required: true,
                      message: "This field is required !",
                    },
                  ]}
                >
                  <Input.Password placeholder="Old Password" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label="New Password"
                  name="new_password"
                  rules={[
                    {
                      required: true,
                      message: "This field is required !",
                    },
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label="Confirm Password"
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: "This field is required !",
                    },
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
              </Col>
              <Form.Item style={{ marginLeft: "8px", marginBottom: "0.5rem" }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {loading ? "Processing" : "Reset Password"}
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
