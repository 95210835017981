import React, { useEffect, useState } from "react";
import { Table, Typography, Drawer, Tag } from "antd";
import { getAllClassNotice } from "../../api/Notice";
import Spinner from "../Spinner";

const { Column } = Table;
const { Text } = Typography;

export default function ClassroomAnnouncement(props) {
  const [data, setData] = useState(null);
  const [details, setDetails] = useState("");
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    getAllClassNotice(
      {
        level_class_id: props.id,
        type: "announcement",
      },
      setData
    );
  }, [props.id]);

  const getDot = (record) => {
    if (record.description && record.description.length > 72) {
      return (
        <Tag
          style={{ marginLeft: "0.5rem", cursor: "pointer" }}
          onClick={() => {
            setDetails({
              title: record.title,
              description: record.description,
            });
            setVisible(true);
          }}
        >
          More
        </Tag>
      );
    } else {
      return "";
    }
  };

  return (
    <div>
      {data && data.data ? (
        <div>
          <Table
            bordered={false}
            dataSource={data.data}
            rowKey={(record) => record.id}
            pagination={false}
          >
            <Column
              title="Title"
              key="title"
              render={(text, record) => (
                <div>
                  <Text>{record.title}</Text>
                </div>
              )}
            />
            <Column
              title="Description"
              key="description"
              render={(text, record) => (
                <div>
                  <Text style={{ cursor: "pointer" }}>
                    {record.description
                      ? record.description.slice(0, 72)
                      : " -- "}
                  </Text>
                  {getDot(record)}
                </div>
              )}
            />
          </Table>
          <Drawer
            title={details.title}
            placement={"bottom"}
            closable={true}
            height="60vh"
            onClose={() => {
              setVisible(false);
              setDetails("");
            }}
            visible={visible}
            key={"bottom"}
          >
            <p>{details.description}</p>
          </Drawer>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
