import React from "react";
import { Card, Descriptions, Row, Col } from "antd";
import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import color from "../../utils/Theme";

export default function ClassroomDetails(props) {
  return (
    <div>
      <Card title="Information">
        <Descriptions>
          <Descriptions.Item label="Subject">{props.subject}</Descriptions.Item>
          <Descriptions.Item label="Year">{props.level}</Descriptions.Item>
          <Descriptions.Item label="Term">{props.term}</Descriptions.Item>
        </Descriptions>
      </Card>
      <Card style={{ marginTop: "1rem" }} title="Class Schedule">
        {props.schedule && props.schedule.length > 0 && (
          <Row gutter={[16, 16]}>
            {props.schedule.map((p, k) => (
              <Col xs={{ span: 24 }} md={{ span: 6 }} lg={4} key={k}>
                <div>
                  <CalendarOutlined style={{ color: color.highlight }} />
                  <span
                    style={{ marginLeft: "0.5rem", color: color.highlight }}
                  >
                    {p.day}
                  </span>
                </div>
                <div>
                  <ClockCircleOutlined style={{ color: color.highlight }} />
                  <span
                    style={{ marginLeft: "0.5rem", color: color.highlight }}
                  >
                    {p.schedule.from + " - " + p.schedule.to}
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </Card>
    </div>
  );
}
