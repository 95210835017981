import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getSingleClassNotice } from "../../api/Notice";
import Spinner from "../../components/Spinner";
import HomeworkPDF from "./HomeworkPDF";

export default function HomeworkPagePDF() {
  const [data, setdata] = useState(null);
  const history = useHistory();
  let info = history.location.search
    ? history.location.search.substring(1).replace("%20", " ")
    : "";
  const { id } = useParams();
  useEffect(() => {
    getSingleClassNotice(id, setdata);
  }, [id]);
  return (
    <div>
      {data && data.data && info ? (
        <HomeworkPDF data={[data.data]} info={info} />
      ) : (
        <Spinner />
      )}
    </div>
  );
}
