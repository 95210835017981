import React from "react";
// import parse from "html-react-parser";
import color from "../../utils/Theme";
import moment from "moment";
import { Button } from "antd";

export default function HomeworkPDF({ data, info }) {
  let image = require("../../assets/raster/iconlite.png");
  return (
    <div>
      <div
        className="section-to-print"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "0",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "white",
            padding: "1.5rem",
            marginBottom: "1rem",
          }}
        >
          <h4 style={{ marginBottom: "0", color: color.shepcolor }}>
            Homework For {info.replace(/-/g, " ")}
          </h4>
          <h4 style={{ marginBottom: "0", color: color.shepcolor }}>
            Print Date: {moment(new Date()).format("DD-MM-YYYY")}
          </h4>
        </div>
        {data.map((m, k) => (
          <div key={m.id} style={{ flexWrap: "wrap" }} className="printComp">
            <h4 style={{ color: color.shepcolor }}>
              {k + 1 + ". "} {m.title}
            </h4>
            <div
              className="homeworkView"
              style={{ marginLeft: "16px", marginTop: "1rem" }}
            >
              {/* {parse(m.description)} */}
            </div>
          </div>
        ))}
      </div>
      <Button
        type="primary"
        style={{ marginTop: "1rem" }}
        onClick={() => {
          window.print();
        }}
      >
        Print Homework
      </Button>
    </div>
  );
}
