import React, { useEffect, useState } from "react";
import { Tabs, Row, Col, Select } from "antd";
import { getClassroomById } from "../api/ClassSchedule";
import ClassroomDetails from "../components/Classroom/ClassroomDetails";
import Spinner from "../components/Spinner";
import ClassroomHomework from "../components/Classroom/ClassroomHomework";
import ClassroomSyllabus from "../components/Classroom/ClassroomSyllabus";
import ClassroomAnnouncement from "../components/Classroom/ClassroomAnnouncement";
import { getTerm } from "../api/Term";
import { getMyClassrooms } from "../api/Class";
import OnlineCLass from "../components/Classroom/OnlineClass";
const { TabPane } = Tabs;
const { Option } = Select;

export default function ManageClassroom() {
  const [data, setData] = useState(null);
  const [term, setTerm] = useState(null);
  const [termId, setTermId] = useState(null);
  const [classId, setClassId] = useState(null);
  const [classroom, setClassroom] = useState(null);

  useEffect(() => {
    getTerm(1, setTerm);
  }, []);

  useEffect(() => {
    if (term && term.terms && term.terms.data && term.terms.data.length > 0) {
      setTermId(term.terms.data[0].id);
    }
  }, [term]);
  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      setClassId(data.data[0].id);
    }
  }, [data]);

  useEffect(() => {
    if (termId !== null) getMyClassrooms(termId, setData);
  }, [termId]);

  useEffect(() => {
    getClassroomById(classId, setClassroom);
  }, [classId]);

  return (
    <div>
      <Row gutter={[16, 0]} style={{ marginBottom: "1rem" }}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Please select term"
            value={termId}
            onChange={(value) => {
              setTermId(value);
            }}
          >
            {term && term.terms && term.terms.data
              ? term.terms.data.map((p, k) => (
                  <Option value={p.id} key={p.id}>
                    {p.title}
                  </Option>
                ))
              : ""}
          </Select>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Please select term"
            value={classId}
            onChange={(value) => {
              setClassId(value);
            }}
          >
            {data && data.data
              ? data.data.map((p, k) => (
                  <Option value={p.id} key={p.id}>
                    {p.subject.name}
                  </Option>
                ))
              : ""}
          </Select>
        </Col>
      </Row>
      {classroom && classroom.data && classId ? (
        <div>
          <Tabs type="card">
            <TabPane tab="Class Information" key="1">
              <ClassroomDetails
                subject={classroom.data.subject.name}
                level={classroom.data.level.name}
                term={classroom.data.term.title}
                schedule={classroom.data.class_schedule}
              />
            </TabPane>
            <TabPane tab="Homework" key="2">
              <ClassroomHomework
                id={classId}
                subject={classroom.data.subject.name}
                level={classroom.data.level.name}
              />
            </TabPane>
            <TabPane tab="Syllabus" key="3">
              <ClassroomSyllabus id={classId} />
            </TabPane>
            <TabPane tab="Announcement" key="5">
              <ClassroomAnnouncement id={classId} />
            </TabPane>
            <TabPane tab="Online Class" key="4">
              <OnlineCLass id={classId} />
            </TabPane>
          </Tabs>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
