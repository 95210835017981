import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { getAllClassNotice } from "../../api/Notice";
import Spinner from "../Spinner";

export default function OnlineCLass(props) {
  const [data, setData] = useState(null);
  useEffect(() => {
    getAllClassNotice(
      {
        level_class_id: props.id,
        type: "link",
      },
      setData
    );
  }, [props.id]);

  return (
    <div>
      {data && data.data ? (
        <Card>
          {data.data.length > 0 && data.data[0].description === "active" ? (
            <div
              className="iframe-container"
              style={{
                overflow: "hidden",
                paddingTop: "56.25%",
                position: "relative",
              }}
            >
              <iframe
                id="zoomFrame"
                title="zoom"
                allow="microphone; camera"
                style={{
                  border: 0,
                  height: "100vh",
                  left: "50%",
                  transform: "translateX(-50%)",
                  position: "absolute",
                  top: 0,
                  minWidth: "360px",
                }}
                src={data.data[0].note}
                frameBorder="0"
              ></iframe>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img
                style={{ width: "200px", marginBottom: "1rem" }}
                src={require("../../assets/vectors/zoom.svg")}
                alt=""
              />
              <h3>Teacher Hasn't Provided any Zoom Link Yet.</h3>
            </div>
          )}
        </Card>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
