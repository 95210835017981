import React, { useEffect, useState } from "react";
import { Table, Button, Typography, Drawer, Tag } from "antd";
import { Link } from "react-router-dom";
import { getAllClassNotice } from "../../api/Notice";
import Spinner from "../Spinner";
import moment from "moment";

const { Column } = Table;
const { Text } = Typography;

export default function ClassroomHomework(props) {
  const [data, setData] = useState(null);
  const [details, setDetails] = useState("");
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    getAllClassNotice(
      {
        level_class_id: props.id,
        type: "homework",
      },
      setData
    );
  }, [props.id]);

  const getDot = (record) => {
    return (
      <Tag
        style={{ marginLeft: "0.5rem", cursor: "pointer" }}
        onClick={() => {
          setDetails({
            title: record.title,
            description: record.description,
          });
          setVisible(true);
        }}
      >
        View
      </Tag>
    );
  };

  return (
    <div>
      {data && data.data ? (
        <div>
          <Table
            bordered={false}
            dataSource={data.data}
            rowKey={(record) => record.id}
            pagination={false}
          >
            <Column
              title="Date"
              key="date"
              render={(text, record, index) => (
                <Text>{moment(record.created_at).format("Do MMM YYYY")}</Text>
              )}
            />
            <Column
              title="Title"
              key="title"
              render={(text, record) => (
                <div>
                  <Text>{record.title}</Text>
                  {getDot(record)}
                </div>
              )}
            />
            <Column
              title="File"
              key="file"
              align="center"
              render={(text, record) => (
                <div>
                  {record.file ? (
                    <a href={record.file}>
                      <Button type="link">Download</Button>
                    </a>
                  ) : (
                    "--"
                  )}
                </div>
              )}
            />
            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <Link
                  to={`/homework-pdf/${record.id}?${
                    props.level
                      ? props.level.replace(" ", "-") + "-" + props.subject
                      : ""
                  }`}
                >
                  <Button type="primary">Print</Button>
                </Link>
              )}
            />
          </Table>
          <Drawer
            title={details.title}
            placement={"bottom"}
            closable={true}
            height="60vh"
            onClose={() => {
              setVisible(false);
              setDetails("");
            }}
            visible={visible}
            key={"bottom"}
          >
            <div className="homeworkView">
              {/* {details.description ? parse(details.description) : ""} */}
            </div>
          </Drawer>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
