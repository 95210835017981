import axios from "axios";
import Url from "../utils/Urls";

export const getAllClassNotice = (data, setData) => {
  axios
    .post(Url + `/student/class-notices/`, data)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getSingleClassNotice = (id, setData) => {
  axios
    .get(Url + `/teacher/notify-students/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
