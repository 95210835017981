import axios from "axios";
import Url from "../utils/Urls";

export const getTerm = (page, setData) => {
  axios
    .get(Url + `/staff/term`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
