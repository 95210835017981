import axios from "axios";
import Url from "../utils/Urls";
import getError from "../utils/GetError";

export const getClassSchedule = (page, setData) => {
  axios
    .get(Url + `/staff/class-schedule?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getClassScheduleByTermLevel = (data, setData) => {
  axios
    .post(Url + `/staff/level-schedule-by-term`, data)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const addNewClassSchedule = (
  data,
  openNotificationWithIcon,
  setData,
  meta
) => {
  axios
    .post(Url + "/staff/class-schedule", data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Class Schedule added Successfully"
      );
      getClassScheduleByTermLevel(meta, setData);
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

export const updateClassSchedule = (
  id,
  data,
  openNotificationWithIcon,
  setData,
  reset,
  meta
) => {
  axios
    .put(Url + `/staff/class-schedule/${id}`, data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Class Schedule updated Successfully"
      );
      getClassScheduleByTermLevel(meta, setData);
      reset();
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

export const getClassroomById = (id, setData) => {
  axios
    .get(Url + `/staff/level-class/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
