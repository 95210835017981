import React, { Suspense, lazy } from "react";
import "./App.less";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import ManageClassroom from "./pages/ManageClassroom";
import HomeworkPagePDF from "./pages/PDF/HomeworkPagePDF";
import PasswordReset from "./pages/PasswordReset";
const ReportCardPagePDF = lazy(() => import("./pages/PDF/ReportCardPagePDF"));
const RegPDFPage = lazy(() => import("./pages/PDF/RegPDFPage"));
const BookListPagePDF = lazy(() => import("./pages/PDF/BookListPagePDF"));
const PaymentPagePDF = lazy(() => import("./pages/PDF/PaymentPagePDF"));
const Book = lazy(() => import("./pages/Book"));
const Attendance = lazy(() => import("./pages/Attendance"));
const AttendanceHistory = lazy(() => import("./pages/AttendanceHistory"));
const ClassRoutine = lazy(() => import("./pages/ClassRoutine"));
const AuthRoute = lazy(() => import("./routes/AuthRoute"));
const StudentProfile = lazy(() => import("./pages/StudentProfile"));
const Login = lazy(() => import("./pages/Login"));
const Payments = lazy(() => import("./pages/Payments"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const BookList = lazy(() => import("./pages/BookList"));

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div className="pageProgress"></div>}>
          <div className="App">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/"
                render={() => <AuthRoute Component={<ManageClassroom />} />}
              />

              <Route
                exact
                path="/payments"
                render={() => <AuthRoute Component={<Payments />} />}
              />
              <Route
                exact
                path="/profile"
                render={() => <AuthRoute Component={<StudentProfile />} />}
              />
              <Route
                exact
                path="/registration-pdf/:id"
                render={() => <AuthRoute Component={<RegPDFPage />} />}
              />
              <Route
                exact
                path="/book-list-pdf/:id"
                render={() => <AuthRoute Component={<BookListPagePDF />} />}
              />
              <Route
                exact
                path="/payment-pdf/:id/:pid"
                render={() => <AuthRoute Component={<PaymentPagePDF />} />}
              />
              <Route
                exact
                path="/report-card-pdf/:id/:tid"
                render={() => <AuthRoute Component={<ReportCardPagePDF />} />}
              />
              <Route
                exact
                path="/reset-password"
                render={() => <AuthRoute Component={<PasswordReset />} />}
              />
              <Route
                exact
                path="/library"
                render={() => <AuthRoute Component={<Book />} />}
              />
              <Route
                exact
                path="/attendance"
                render={() => <AuthRoute Component={<Attendance />} />}
              />
              <Route
                exact
                path="/attendance-history/:id/:tid"
                render={() => <AuthRoute Component={<AttendanceHistory />} />}
              />
              <Route
                exact
                path="/homework-pdf/:id"
                render={() => <AuthRoute Component={<HomeworkPagePDF />} />}
              />
              <Route
                exact
                path="/class-routine/"
                render={() => <AuthRoute Component={<ClassRoutine />} />}
              />
              <Route
                exact
                path="/my-book-list"
                render={() => <AuthRoute Component={<BookList />} />}
              />
              <Route component={ErrorPage} />
            </Switch>
          </div>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
