import axios from "axios";
import Url from "../utils/Urls";
import getError from "../utils/GetError";
export const passwordReset = (data, openNotificationWithIcon, reset) => {
  axios
    .post(Url + "/student/change-password", data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Password Reset Successful"
      );
      reset(true);
    })
    .catch((err) => {
      reset(false);
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};
